/** @format */

import FacebookIcon from '@/assets/icons/facebook_icon.svg';
import LinkedInIcon from '@/assets/icons/icon_linkedin.svg';
import InstagramIcon from '@/assets/icons/instagram_icon.svg';
import YoutubeIcon from '@/assets/icons/youtube_icon.svg';
import Logo from '@/assets/logo-blue.svg';
import { i18nContext } from '@/context/i18nContext';
import { useGtmLabel } from '@/hooks/useGtmLabel';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import { GTMFooterClick } from '@/lib/google-tag-manager';
import { Company, MenuItem } from '@/types/page';
import { Entry } from 'contentful';
import { useRouter } from 'next/router';
import { FunctionComponent as FC, useContext, useEffect, useMemo, useState } from 'react';
import { BREAKPOINTS } from 'src/theme/mixins';
import { ThemeContext, styled } from 'styled-components';
import { Klaviyo } from '../Klaviyo/Klaviyo';
import { Icon } from '../ui/Icon';
import { Container } from '../ui/Layout';
import { OutboundLink } from '../ui/Link';
import { FooterInfo, FooterInfoLink, NavbarLinkSecond } from '../ui/Typography';
import { ChangeLanguage } from './ChangeLanguage';
import {
  ChangeLanguageFooterWrapper,
  ChangeLanguageFooterWrapperHidden,
  FooterWrapper,
  LegalInfo,
  PoliciesWrapper,
  Separator,
  SocialsWrapper,
  StyledFooter,
  StyledLogo,
  TitleGroup,
  WrapperLeftFooter,
  WrapperLogo,
  WrapperRightFooter,
  WrapperRoute,
  WrapperRouteList,
  WrapperTerms,
} from './Footer.style';

const prod = process.env.NEXT_PUBLIC_APP_ENV === 'prod';

export const Footer: FC<Props> = ({ footerItems, company, pageType = '', landing }) => {
  const theme = useContext(ThemeContext);
  const t = useContext(i18nContext);
  const windowWidth = useWindowWidth();
  const [showChangeLanguageMobile, setShowChangeLanguageMobile] = useState(false);
  const { locale, route } = useRouter();
  const allFooterItems = useMemo(() => footerItems.map(item => item.fields), [footerItems]);
  const { description, legalInfo, policies } = useMemo(() => company.fields, [company]);

  const { getGTMLabel } = useGtmLabel();

  const updateCookiesConset = () => {
    if (!window) return;
    const _window: any = window;

    _window.Cookiebot.renew();
  };

  useEffect(() => {
    if (!windowWidth) return;
    setShowChangeLanguageMobile(windowWidth <= parseInt(BREAKPOINTS['lg'].replace('px', '')));
  }, [windowWidth]);
  const handleGTMSubItemFooterClick = (subItem: Entry<MenuItem>) => {
    let gtmType;
    const linkId = getGTMLabel(subItem.fields);

    if (subItem.fields.url && subItem.fields.url.fields.link) gtmType = undefined;
    else gtmType = subItem.fields.url?.fields.gtmType;

    GTMFooterClick(linkId, gtmType);
  };

  const renderFooterItems = (items: MenuItem[]) => {
    return items.map((item, index) => {
      return (
        <WrapperRoute key={item.label} $home={pageType === 'home'}>
          {item.url && (
            <ChangeLanguageFooterWrapperHidden>
              <NavbarLinkSecond
                item={item.url}
                locale={locale}
                data-active={route === `/${item.url.fields.link?.fields.slug}`}
                onClick={() => GTMFooterClick(getGTMLabel(item))}
              >
                <TitleGroup>{item.label}</TitleGroup>
              </NavbarLinkSecond>
              {showChangeLanguageMobile && index == items.length - 1 ? (
                <ChangeLanguage position="footer" variant="dark" />
              ) : (
                ''
              )}
            </ChangeLanguageFooterWrapperHidden>
          )}
          {item.subItems && (
            <>
              <TitleGroup onClick={() => GTMFooterClick(item.id)}>{item.label}</TitleGroup>
              {item.subItems.map(subItem => {
                if (subItem.fields.url)
                  return (
                    <NavbarLinkSecond
                      onClick={() => handleGTMSubItemFooterClick(subItem)}
                      key={subItem.fields.label}
                      item={subItem.fields.url}
                      locale={locale}
                      data-active={route === `/${subItem.fields.url?.fields.link?.fields.slug}`}
                    />
                  );
              })}
            </>
          )}
        </WrapperRoute>
      );
    });
  };

  return (
    <StyledFooter>
      <Container>
        <FooterWrapper>
          <WrapperLeftFooter>
            <WrapperLogo>
              <StyledLogo src={Logo} alt="logo" width={179} height={52} />
              <FooterInfo>{description}</FooterInfo>
              <Klaviyo />
              <SocialsWrapper>
                <OutboundLink onClick={() => GTMFooterClick('instagram')} href={company.fields.instagram}>
                  <Icon
                    icon={InstagramIcon}
                    hoverColor={theme?.color.teal.light}
                    width={34}
                    height={34}
                    color={theme?.color.teal.dark}
                  />
                </OutboundLink>
                <OutboundLink onClick={() => GTMFooterClick('linkedin')} href={company.fields.linkedin}>
                  <Icon
                    icon={LinkedInIcon}
                    hoverColor={theme?.color.teal.light}
                    width={34}
                    height={34}
                    color={theme?.color.teal.dark}
                  />
                </OutboundLink>
                <OutboundLink onClick={() => GTMFooterClick('youtube')} href={company.fields.youtube}>
                  <Icon
                    icon={YoutubeIcon}
                    hoverColor={theme?.color.teal.light}
                    width={40}
                    height={50}
                    color={theme?.color.teal.dark}
                  />
                </OutboundLink>
                <OutboundLink onClick={() => GTMFooterClick('facebook')} href={company.fields.facebook}>
                  <Icon
                    icon={FacebookIcon}
                    hoverColor={theme?.color.teal.light}
                    width={34}
                    height={34}
                    color={theme?.color.teal.dark}
                  />
                </OutboundLink>
              </SocialsWrapper>
            </WrapperLogo>
            <ChangeLanguageFooterWrapper>
              {!showChangeLanguageMobile && <ChangeLanguage position="footer" variant="dark" landing={landing} />}
            </ChangeLanguageFooterWrapper>
          </WrapperLeftFooter>
          <WrapperRightFooter>
            <WrapperRouteList $home={pageType === 'home'}>
              {allFooterItems && renderFooterItems(allFooterItems)}
            </WrapperRouteList>
          </WrapperRightFooter>
        </FooterWrapper>
        <Separator />
        <WrapperTerms>
          {legalInfo && (
            <LegalInfo color={theme?.color.black}>
              {legalInfo.length > 1 ? (
                <>
                  {legalInfo.slice(0, legalInfo.length - 1).join(' - ')}
                  <br />
                  {legalInfo[legalInfo.length - 1]}
                </>
              ) : (
                legalInfo
              )}
            </LegalInfo>
          )}
          <PoliciesWrapper>
            {policies.map(policy => (
              <FooterInfoLink
                color={theme?.color.black}
                key={policy.fields.label}
                item={policy}
                locale={locale}
                onClick={() => {
                  if ('link' in policy.fields) {
                    GTMFooterClick(policy.fields.link.fields.id);
                  }
                }}
              />
            ))}
            <StyledAnchor as="button" onClick={updateCookiesConset}>
              {t.common.footer_manage_cookies}
            </StyledAnchor>
          </PoliciesWrapper>
        </WrapperTerms>
      </Container>
    </StyledFooter>
  );
};

type Props = {
  footerItems: Entry<MenuItem>[];
  company: Entry<Company>;
  pageType?: string;
  landing?: boolean;
};

const StyledAnchor = styled.a`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => theme.font.size[0.6875]};
  font-style: normal;
  font-weight: 300;
  line-height: 1.3125rem;
  letter-spacing: 0.0275rem;
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.color.teal.dark};
  }
`;
