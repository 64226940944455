/** @format */

import { BlogPostCard, NewsCard } from '@/types/news';
import { PageContent as PageContentType } from '@/types/page';
import dynamic from 'next/dynamic';
import { FunctionComponent as FC, Fragment } from 'react';
import { Loader } from '../ui/Loader';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';

const ContactFormsSection = dynamic(() => import('../ContactsFormSection'), {
  loading: () => <Loader />,
});

const GovernanceFrameWrapper = dynamic(
  () => import('../DonutSection/GovernanceFrameWrapper').then(mod => mod.GovernanceFrameWrapper),
  {
    loading: () => <Loader />,
  },
);

const ImageText = dynamic(() => import('../ImageText').then(mod => mod.ImageText), {
  loading: () => <Loader />,
});
const ImageTextBanner = dynamic(() => import('../ImageText').then(mod => mod.ImageTextBanner), {
  loading: () => <Loader />,
});
const ImageTextManifest = dynamic(() => import('../ImageText').then(mod => mod.ImageTextManifest), {
  loading: () => <Loader />,
});
const ImageTextTarget = dynamic(() => import('../ImageText').then(mod => mod.ImageTextTarget), {
  loading: () => <Loader />,
});
const IconsDescriptionSection = dynamic(
  () => import('../IconsDescriptionSection').then(mod => mod.IconsDescriptionSection),
  {
    loading: () => <Loader />,
  },
);
const SectionInfoSolutions = dynamic(
  () => import('../SectionInfoSolution/SectionInfoSolutions').then(mod => mod.SectionInfoSolutions),
  {
    loading: () => <Loader />,
  },
);
const SustainabilityNumbersSection = dynamic(
  () => import('../SustainabilityNumbersSection').then(mod => mod.SustainabilityNumbersSection),
  {
    loading: () => <Loader />,
  },
);
const SectionParnerCard = dynamic(() => import('../ui/SectionPartnerCard').then(mod => mod.SectionParnerCard), {
  loading: () => <Loader />,
});
const TextCardsSection = dynamic(() => import('../TextCardsSection').then(mod => mod.TextCardsSection), {
  loading: () => <Loader />,
});
const UtilityPageBody = dynamic(() => import('../UtilityPageBody').then(mod => mod.UtilityPageBody), {
  loading: () => <Loader />,
});
const CenterBigTitle = dynamic(() => import('../ui/CenterBigTitle').then(mod => mod.CenterBigTitle), {
  loading: () => <Loader />,
});
const SectionInfoSatistics = dynamic(
  () => import('../ui/SectionInfoStatistics').then(mod => mod.SectionInfoSatistics),
  {
    loading: () => <Loader />,
  },
);
const SectionColumns = dynamic(() => import('../ui/SectionColumns').then(mod => mod.SectionColumns), {
  loading: () => <Loader />,
});
const JobsPositionsSection = dynamic(() => import('../JobsPositionsSection').then(mod => mod.JobsPositionsSection), {
  loading: () => <Loader />,
});
const SectionVideo = dynamic(() => import('../SectionVideo/SectionVideo').then(mod => mod.SectionVideo), {
  loading: () => <Loader />,
});
const SectionInfoMap = dynamic(() => import('../SectionInfoMap/SectionInfoMap').then(mod => mod.SectionInfoMap), {
  loading: () => <Loader />,
});

const TeamSection = dynamic(() => import('../TeamSection').then(mod => mod.TeamSection), {
  loading: () => <Loader />,
});

const ProjectsSection = dynamic(() => import('../ProjectSection').then(mod => mod.ProjectsSection), {
  loading: () => <Loader />,
});

const AwardsCardsSection = dynamic(() => import('../AwardsCardsSection').then(mod => mod.AwardsCardsSection), {
  loading: () => <Loader />,
});

const TextAnimation = dynamic(() => import('../ui/TextAnimation').then(mod => mod.TextAnimation), {
  loading: () => <Loader />,
});

const SectionWithSlider = dynamic(() => import('../SectionWithSlider').then(mod => mod.SectionWithSlider), {
  loading: () => <Loader />,
});

const SectionWithGallery = dynamic(() => import('../SectionWithSlider').then(mod => mod.SectionWithGallery), {
  loading: () => <Loader />,
});

const SectionWithAllSliders = dynamic(() => import('../SectionWithSlider').then(mod => mod.SectionWithAllSliders), {
  loading: () => <Loader />,
});

const AnimatedCardsSection = dynamic(() => import('../AnimatedCardsSection').then(mod => mod.AnimatedCardsSection), {
  loading: () => <Loader />,
});

const BlogPostSlider = dynamic(() => import('../BlogPostSlider').then(mod => mod.BlogPostSlider), {
  loading: () => <Loader />,
});

const TimelineSection = dynamic(() => import('../TimelineSection').then(mod => mod.TimelineSection), {
  loading: () => <Loader />,
});

export const PageContent: FC<Props> = ({ content, title, allNews, allBlogPosts, newsSlugs }) => {
  const calculateMoreMargin = (title: String) => title !== 'Joivy Home';

  return (
    <>
      {content.map(({ id, fields }, i) => (
        <Fragment key={`${id}-${i}`}>
          {id === 'centerBigTitle' &&
            (fields.type === 'animations-only-title' || fields.type === 'animations' ? (
              <TextAnimation
                label={fields.body}
                backgroundColor={fields.background}
                textColor={fields.textColor}
                textSecondColor={fields.textSecondColor}
                type={fields.type}
              />
            ) : (
              <CenterBigTitle
                id={fields.id}
                label={fields.body}
                backgroundColor={fields.background}
                textColor={fields.textColor}
                textSecondColor={fields.textSecondColor}
                type={fields.type}
                mobileAlign={fields.mobileAlign}
              />
            ))}
          {id === 'imagetext' && (
            <>
              {fields.type === 'withSlider' && (
                <SectionWithGallery
                  key={title}
                  text={fields.text}
                  images={fields.images}
                  background={fields.background}
                  alignment={fields.alignment}
                  withCounter
                />
              )}
              {fields.type === 'withTarget' && <ImageTextTarget {...fields} />}
              {fields.type === 'withBanner' && <ImageTextBanner {...fields} />}
              {fields.type === 'manifest' && <ImageTextManifest {...fields} />}
              {/* @ts-ignore */}
              {(!fields.type || fields.type === 'withBigText') && <ImageText {...fields} />}
            </>
          )}
          {id === 'statisticsData' && <SectionInfoSatistics data={fields.data} />}
          {id === 'sectionInfoMap' && <SectionInfoMap data={fields} />}
          {id === 'utilityBody' && <UtilityPageBody body={fields.body} />}
          {id === 'team' && !fields.small && <TeamSection title={fields.title} cards={fields.teamMembers} />}
          {id === 'sustainabilityNumbersSection' && (
            <SustainabilityNumbersSection
              title={fields.title}
              icons={fields.icons}
              numbers={fields.numbers}
              description={fields.description}
              negativeMargin={fields.negativeMargin}
            />
          )}
          {id === 'list' && (
            <>
              {fields.type === 'contactForms' && <ContactFormsSection forms={fields.list} />}
              {fields.type === 'ourSolutions' && <SectionInfoSolutions columns={fields.list} />}
              {fields.type === 'infoColumns' && <SectionColumns columns={fields.list} />}
              {fields.type === 'productsSection' && <SectionWithSlider text={fields.text} products={fields.list} />}
              {fields.type === 'cardsList' && (
                <SectionWithAllSliders text={fields.text} images={fields.list} background={'white'} />
              )}
              {fields.type === 'timeline' && <TimelineSection title={fields.text} times={fields.list} />}
              {fields.type === 'awards' && (
                <AwardsCardsSection title={fields.text} cards={fields.list} background={fields.background} />
              )}
              {fields.type === 'ourProjectsSection' && <ProjectsSection title={fields.text} projects={fields.list} />}

              {fields.type === 'sectionCardPartners' && <SectionParnerCard text={fields.text} list={fields.list} />}
              {fields.type === 'animatedCard' && (
                <AnimatedCardsSection
                  title={fields.text}
                  cards={fields.list}
                  link={fields.link}
                  pageName={title}
                  background={fields.background}
                  moreMargin={calculateMoreMargin(title)}
                />
              )}
              {fields.type === 'iconsList' && (
                <IconsDescriptionSection
                  title={fields.text}
                  icons={fields.list}
                  background={fields.background ?? ''}
                  negativeMargin={fields.negativeMargin}
                />
              )}
              {fields.type === 'centerBigTitle' && <TextCardsSection title={fields.text} cards={fields.list} />}
              {fields.type === 'jobs' && (
                <JobsPositionsSection title={fields.text} background={fields.background} link={fields.link} />
              )}
              {fields.type === 'team' && <GovernanceFrameWrapper fields={fields.list} title={fields.text} />}
              {fields.type === 'homePageNews' && newsSlugs && (
                <BlogPostSlider
                  allBlogPosts={allBlogPosts}
                  allNews={allNews}
                  newsSlugs={newsSlugs}
                  link={fields.text}
                />
              )}
            </>
          )}
          {id === 'sectionVideo' && <SectionVideo videoURL={fields.videoUrl} />}
        </Fragment>
      ))}
    </>
  );
};
type Props = {
  content: PageContentType;
  title: string;
  allNews?: NewsCard[];
  allBlogPosts?: BlogPostCard[];
  newsSlugs?: {
    [key: string]: string;
  };
};
